.waypointPopover {
  :global(.ant-popover-inner) {
    :global(.ant-popover-inner-content) {
      padding: 0;
    }
  }
}

.waypointNoArrowPopover {
  :global(.ant-popover-arrow) {
    display: none;
  }
}

.waypointNoPaddingPopover {
  padding-top: 0 !important;
}
