.waypointDropdownDefaultOpen {
  border-color: var(--border-accent);
  box-shadow: 0px 0px 2px 0.5px var(--background-accent);
}

.waypointDropdownSuccessOpen {
  border-color: var(--border-success);
  box-shadow: 0px 0px 2px 0.5px var(--background-success);
}

.waypointDropdownWarningOpen {
  border-color: var(--border-warning);
  box-shadow: 0px 0px 2px 0.5px var(--background-warning);
}

.waypointDropdownErrorOpen {
  border-color: var(--border-error);
  box-shadow: 0px 0px 2px 0.5px var(--background-error);
}

.waypointInputPlaceholder::placeholder {
  color: var(--slate-500);
}
