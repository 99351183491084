.editor {
  :global(.react-mde) {
    height: 100%;
    display: flex;
    flex-direction: column;

    :global(.mde-tabs) {
      button {
        padding: 5px;
      }
    }

    div:nth-child(2) {
      flex-grow: 1;

      :global(.mde-textarea-wrapper) {
        height: 100%;

        textarea {
          min-height: 100% !important;
          resize: none;
        }
      }
    }
  }
}