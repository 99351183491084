.waypointTooltip {
  opacity: 1;
  max-width: max-content;
}
.waypointLowSuccessTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-lightSuccess);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-lightSuccess);
  }
}
.waypointLowWarningTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-lightWarning);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-lightWarning);
  }
}
.waypointLowErrorTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-lightError);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-lightError);
  }
}
.waypointLowInformationTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-lightInformation);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-lightInformation);
  }
}

.waypointHighSuccessTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-success);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-success);
  }
}
.waypointHighWarningTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-warning);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-warning);
  }
}
.waypointHighErrorTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-error);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-error);
  }
}
.waypointHighInformationTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-information);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-information);
  }
}
.waypointAccentTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-accent);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-accent);
  }
}
.waypointGenericTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-inverse);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-inverse);
  }
}
.waypointSecondaryTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-secondary);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-secondary);
  }
}
.waypointTertiaryTooltip {
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--background-tertiary);
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-tertiary);
  }
}
