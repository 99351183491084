:root {
  --ra-admonition-background-color: var(--ifm-alert-background-color);
  --ra-admonition-border-width: var(--ifm-alert-border-width);
  --ra-admonition-border-color: var(--ifm-alert-border-color);
  --ra-admonition-border-radius: var(--ifm-alert-border-radius);
  --ra-admonition-color: var(--ifm-alert-color);
  --ra-admonition-padding-vertical: var(--ifm-alert-padding-vertical);
  --ra-admonition-padding-horizontal: var(--ifm-alert-padding-horizontal);
  --ra-color-note: var(--ifm-color-secondary);
  --ra-color-important: var(--ifm-color-info);
  --ra-color-tip: var(--ifm-color-success);
  --ra-color-caution: var(--ifm-color-warning);
  --ra-color-warning: var(--ifm-color-danger);
  --ra-color-text-dark: var(--ifm-color-gray-900);
}