@font-face {
  font-family: 'Manrope';
  src: url('./Manrope/Manrope-VariableFont_wght.ttf');
  font-weight: 400 800;
}

html,
body,
#root {
  font-family: 'Manrope' !important;
}
