@mixin buttonShadow($color) {
  box-shadow: 0 0 0.5rem 0 $color !important;
}

.waypointButtonAccentShadow {
  &:hover {
    @include buttonShadow(var(--background-accent));
  }
}

.waypointButtonSecondaryShadow {
  &:hover {
    box-shadow: 0 0 3px 1px rgba(15, 23, 42, 0.5) !important;
  }
}

.waypointButtonWarningShadow {
  &:hover {
    @include buttonShadow(var(--background-warning));
  }
}

.waypointButtonErrorShadow {
  &:hover {
    @include buttonShadow(var(--background-error));
  }
}

@mixin notLinkOrDisabled {
  &:not(a):not(:global(.p-button-loading)):not(:disabled) {
    @content;
  }
}

@mixin buttonShadow($color) {
  box-shadow: 0 0 0.5rem 0 $color;
}

@mixin removeBoxShadow {
  box-shadow: none;
}

:global(.p-button) {
  border-radius: var(--border-radius);

  :global(.p-button-label) {
    font-weight: var(--font-weight-demi);
    line-height: 1rem;
  }

  &:global(.p-button-sm) {
    padding: 0.3125rem 0.5rem;
  }

  &:global(.p-button-md) {
    padding: 0.6875rem 1rem;
    font-size: 1rem;
  }

  &:global(.p-button-lg) {
    padding: 1.3125rem 2rem;
    font-size: 1.125rem;
  }

  &:global(.p-button-default) {
    &:global(.p-button-primary) {
      &,
      &:hover {
        background-color: var(--background-accent);
        border-color: var(--border-accent);
        color: var(--white);
      }

      &:hover {
        box-shadow: 0 0 0.5rem 0 #006e75;
      }

      &:active {
        background: var(--tealPath-800);
        border-color: var(--tealPath-800);
        @include removeBoxShadow;
      }

      &:disabled {
        background: rgba(165, 215, 219, 0.16);
        border-color: rgba(165, 215, 219, 0.16);
        color: var(--content-disabled);
      }
    }

    &:global(.p-button-secondary) {
      background: var(--white);
      border: 1px solid var(--border-primary);

      @include notLinkOrDisabled {
        &:hover {
          background: unset;
          border-color: var(--border-primary);
          color: unset;
        }

        &:active {
          background: var(--gray-100);
          border-color: var(--border-primary);
          box-shadow: 0 0 0 0 rgba(15, 23, 42, 0.5);
          color: unset;
        }

        &:disabled {
          background: var(--background-disabled);

          :global(.p-button-label) {
            color: var(--content-disabled);
          }
        }
      }

      &:global(.p-button-loading) {
        &:disabled {
          background: var(--background-tertiary);
        }

        @include notLinkOrDisabled {
          :global(.p-button-label) {
            color: var(--content-primary);
          }
        }
      }

      &,
      :global(.p-button-label) {
        color: var(--content-primary);
      }

      &:hover {
        background: var(--white);
        box-shadow: 0 0 0.5rem 0 rgba(15, 23, 42, 0.5);
        border-color: var(--border-primary);
      }

      &:active {
        background: var(--gray-100);
        box-shadow: 0 0 0 0 rgba(15, 23, 42, 0.5);
      }
    }

    &:global(.p-button-tertiary) {
      background: transparent;
      border-color: transparent;
      color: var(--content-accent);

      @include notLinkOrDisabled {
        &:hover {
          background: transparent;
          border-color: transparent;
          box-shadow: 0 0 0.5rem 0 rgba(15, 23, 42, 0.5);
          color: var(--content-accent);
        }

        &:active {
          background: transparent;
          border-color: var(--border-accent);
          color: var(--content-accent);
          @include removeBoxShadow;
        }
      }
    }

    &:global(.p-button-warning) {
      background: var(--background-warning);

      &:global(.p-button-loading) {
        &:disabled {
          border-color: var(--background-tertiary);
          background: var(--background-tertiary);
          color: var(--content-warning);
        }
      }

      &:not(:global(.p-button-loading)):disabled {
        border-color: rgba(253, 230, 138, 0.16);
        background: rgba(253, 230, 138, 0.16);
      }

      @include notLinkOrDisabled {
        &:hover {
          background: var(--background-warning);
          border-color: var(--border-warning);
          @include buttonShadow(var(--background-warning));
        }

        &:active {
          background: var(--amber-500);
        }
      }
    }

    &:global(.p-button-error) {
      &:not(:global(.p-button-loading)):disabled {
        background: rgba(254, 202, 202, 0.16);
        border-color: rgba(254, 202, 202, 0.16);
        color: var(--content-disabled);
      }

      @include notLinkOrDisabled {
        background: var(--background-error);
        border-color: var(--border-error);
        color: var(--white);

        &:hover {
          background: var(--background-error);
          border-color: var(--border-error);
          @include buttonShadow(var(--background-error));
        }

        &:active {
          background: var(--red-900);
          border-color: var(--red-900);
        }
      }
    }
  }

  &:global(.p-button-text) {
    border: 0;
    position: relative;
    border-radius: 0;
    padding: 0;

    &,
    &:hover {
      background: transparent;
    }

    &:after {
      background: var(--background-accent);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      opacity: 0;
      position: absolute;
      transition: opacity 0.12s ease-in-out;
      width: 100%;
    }

    @include notLinkOrDisabled {
      &,
      &:hover {
        background: transparent;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &:active {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &:global(.p-button-outlined) {
    border-color: var(--background-accent);

    &:not(:global(.p-button-loading)):disabled {
      border-color: var(--border-disabled);
      color: var(--content-disabled);
    }

    &:global(.p-button-loading) {
      &:disabled {
        background: var(--background-tertiary);
        border-color: var(--background-tertiary);
        color: var(--content-accent);
      }
    }

    &:global(.p-button-warning) {
      &:global(.p-button-loading) {
        &:disabled {
          color: var(--content-warning);
        }
      }

      @include notLinkOrDisabled {
        border-color: var(--border-warning);

        &,
        &:hover {
          color: var(--content-warning);
        }

        &:hover {
          @include buttonShadow(var(--background-warning));
        }

        &:active {
          background: var(--background-lightWarning);
          @include removeBoxShadow;
        }
      }
    }

    &:global(.p-button-error) {
      @include notLinkOrDisabled {
        &,
        &:hover {
          border-color: var(--border-error);
          color: var(--content-error);
        }

        &:hover {
          @include buttonShadow(var(--background-error));
        }

        &:active {
          background: var(--background-lightError);
          @include removeBoxShadow;
        }
      }
    }

    @include notLinkOrDisabled {
      &,
      &:hover {
        background: transparent;
        color: var(--content-accent);
      }

      &:hover {
        @include buttonShadow(var(--background-accent));
      }

      &:active {
        background: var(--background-lightAccent);
        @include removeBoxShadow;
      }
    }
  }
}

:global(.p-disabled),
:global(.p-component:disabled) {
  opacity: 1;
}
