.admonition {
  margin-bottom: 1em;
  padding: 15px 30px 15px 15px;
}

.admonition h5 {
  margin-top: 0 !important;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.admonition-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.2em;
}

.admonition-icon svg {
  display: inline-block;
  width: 22px;
  height: 22px;
  stroke-width: 0;
}

.admonition-content > :last-child {
  margin-bottom: 0;
}

/* default for custom types */
.admonition {
  background-color: rgba(118, 51, 219, 0.1);
  border-left: 8px solid #7633db;
}

.admonition h5 {
  color: #7633db;
}

.admonition .admonition-icon svg {
  stroke: #7633db;
  fill: #7633db;
}

/** native types */
.admonition-caution {
  background-color: rgba(230, 126, 34, 0.1);
  border-left: 8px solid #e67e22;
}

.admonition-caution h5 {
  color: #e67e22;
}

.admonition-caution .admonition-icon svg {
  stroke: #e67e22;
  fill: #e67e22;
}

.admonition-tip {
  background-color: rgba(46, 204, 113, 0.1);
  border-left: 8px solid #2ecc71;
}

.admonition-tip h5 {
  color: #2ecc71;
}

.admonition-tip .admonition-icon svg {
  stroke: #2ecc71;
  fill: #2ecc71;
}

.admonition-warning {
  background-color: rgba(231, 76, 60, 0.1);
  border-left: 8px solid #e74c3c;
}

.admonition-warning h5 {
  color: #e74c3c;
}

.admonition-warning .admonition-icon svg {
  stroke: #e74c3c;
  fill: #e74c3c;
}

.admonition-important {
  background-color: rgba(52, 152, 219, 0.1);
  border-left: 8px solid #3498db;
}

.admonition-important h5 {
  color: #3498db;
}

.admonition-important .admonition-icon svg {
  stroke: #3498db;
  fill: #3498db;
}

.admonition-note {
  background-color: rgba(241, 196, 15, 0.1);
  border-left: 8px solid #f1c40f;
}

.admonition-note h5 {
  color: #f1c40f;
}

.admonition-note .admonition-icon svg {
  stroke: #f1c40f;
  fill: #f1c40f;
}